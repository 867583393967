@import 'styles/config.scss';
@import 'ng-pick-datetime/assets/style/picker.min.css';
@import 'ngx-toastr/toastr';
@import 'pretty-checkbox/src/pretty-checkbox.scss';

:root {
  --main-color: rgb(168, 28, 7);
  --secondary-color: rgb(243, 226, 223);
  --ternary-color: rgb(243, 226, 223);
}

$pretty--colors: (
  primary: black,
);

.pretty.p-img-label {
  label:before,
  label:after,
  .icon,
  .svg,
  .img {
    top: calc((0% - (100% - 5.8em)) - 8%) !important;
  }
}

html {
  height: 100%;
}

html,
body,
.container {
  font-family: $body-font;
  height: 100%;
}

a {
  text-decoration: none;
  color: var(--main-color);
}

.font-25 {
  font-size: 25px !important;
}

.main-body {
  width: 70%;
  margin: auto;
}

.page-title {
  color: #444;
  font-size: 20pt;
  font-weight: 300;
  margin: 45px 0 35px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
  letter-spacing: 1px;
  line-height: 28px;
  text-transform: uppercase;
  min-height: 28px;
}

.break-word {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.required {
  color: #dd3217 !important;
}

.table-text-red {
  color: var(--main-color) !important;
}

.yellow-text {
  color: #ffcc00 !important;
}

.blue-text {
  color: #006db0 !important;
}

.blue-text-underline {
  color: #006db0 !important;
  text-decoration-line: underline;
  text-decoration-color: #006db0;
}

.grey-text {
  color: grey !important;
}

.table-link {
  color: var(--main-color) !important;
  cursor: pointer;
}

.table th {
  padding: 8px;
  vertical-align: top;
  border-top: 0;
}

.table td {
  padding: 8px;
}

.red-link {
  color: var(--main-color) !important;
  cursor: pointer;
}

.red-link:hover {
  text-decoration: underline !important;
}

.black-link {
  cursor: pointer;
}

.black-link:hover {
  text-decoration: underline !important;
}

.pointer {
  cursor: pointer;
}

.dot {
  height: 7px;
  width: 7px;
  background-color: var(--main-color);
  border-radius: 50%;
  margin-right: 3px;
  display: inline-block;
}

.info-box {
  margin: 16px 0 10px;
  padding: 10px 10px;
  border-radius: 10px;
  display: block;
  background: var(--secondary-color);
  font-size: smaller;
}

.generalInput {
  display: inline-block;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  outline: none;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-shadow: none;
  box-shadow: none !important;
  background: linear-gradient(to bottom, #ffffff 0%, #f0f0f0 100%);
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
}

.generalInput:disabled {
  background: #f1f3f5;
}

.generalDropdown {
  margin: 0;
  border-radius: 5px;
  border: 1px solid rgb(169, 169, 169);
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #333;
  background: linear-gradient(to bottom, #ffffff 0%, #f0f0f0 100%);

  &:disabled {
    color: black;
    background: #e9ecef;
  }
}

.input-group-text {
  background: linear-gradient(to bottom, #ffffff 0%, #f0f0f0 100%) !important;
}

.input-group.custom {
  flex-wrap: nowrap;

  .amount {
    flex: 1;

    input {
      border-radius: 5px 0 0 5px;
    }
  }
  .currency input,
  .currency select {
    border-radius: 0 5px 5px 0 !important;
    border-left: none;
    min-width: auto !important;
  }
}

.formInput {
  width: 100%;
  font-size: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  color: #555;
  outline: none;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  display: inline-block;
  background: linear-gradient(to bottom, #ffffff 0%, #f0f0f0 100%);
}

.generalTextarea {
  width: 100%;
  padding: 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  font-size: 11pt;
  border: 1px solid #ccc;
  background: #f0f0f0;
  background: -moz-linear-gradient(top, #ffffff 0%, #f0f0f0 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #f0f0f0));
  background: -webkit-linear-gradient(top, #ffffff 0%, #f0f0f0 100%);
  background: -o-linear-gradient(top, #ffffff 0%, #f0f0f0 100%);
  background: -ms-linear-gradient(top, #ffffff 0%, #f0f0f0 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #f0f0f0 100%);
  color: #555;
  margin-top: 0;
  outline: none;
  height: 150px;
}

.lg-button {
  padding: 8px 15px !important;
  background: var(--main-color);
  display: inline-block;
  margin: 16px 0 10px;
  cursor: pointer;
  min-width: 130px !important;
  border: 0 solid #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  color: #fff !important;
  text-align: center;
  font-size: 16px !important;
  line-height: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.lg-button-secondary {
  @extend .lg-button;

  background-color: #6c757d;
  border-color: #6c757d;
}

.lg-button:disabled {
  background: var(--main-color);
  filter: brightness(80%);
  cursor: default;
}

.lg-button-confirm {
  padding: 8px 15px !important;
  background: #16a019;
  display: inline-block;
  margin: 16px 0 10px;
  cursor: pointer;
  min-width: 130px !important;
  border: 0 solid #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  color: #fff !important;
  text-align: center;
  font-size: 16px !important;
  line-height: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.lg-button-confirm:disabled {
  background: #16a019;
  filter: brightness(80%);
  cursor: default;
}

.white-button {
  background: white;
  color: var(--main-color) !important;
  border: 1px solid var(--main-color);
}

.white-button:focus {
  background: white;
  border: none;
  cursor: pointer;
  color: #707070;
  outline: #707070;
}

.normal-button {
  padding: 10px 16px;
  font-size: 16px;
  line-height: 1.3333333;
  border-radius: 6px;
  background: var(--main-color);
  color: #fff;
}

.btn-group-stepper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.btn-stepper {
  background-color: var(--main-color);
  color: white;
}

.section-number {
  font-weight: normal;
  background: none;
  color: #e2e2e2;
  font-size: 70px;
  border-radius: 0;
  padding: 0;
  line-height: 54px;
}

.section-title {
  font-size: 20px;
  margin-left: 5px;
  //font-weight: bolder;
}

.closing-balance-secondary {
  color: var(--main-color);
  font-size: 18px;
}

.wrap {
  padding-left: 15px;
}

.closing-balance {
  color: var(--main-color);
  font-size: 20px;
}

.ngx-pagination .current {
  background-color: var(--main-color) !important;
}

.dropdown-menu {
  min-width: 1rem !important;
}

.dropdown-item {
  padding: 0.25rem 0.3rem !important;
}

.nav-link-wrapper {
  height: 100%;
  width: 111px;
  padding: 0 5px;
  border-left: 1px solid white;
}

.nav-link-wrapper:last-of-type {
  border-right: 1px solid white;
}

.tab-link {
  color: var(--ternary-color) !important;
}

.card {
  border-radius: 5px !important;
  border-color: var(--main-color) !important;
}

.sm-button {
  padding: 5px 12px;
  font-size: 12px;
  line-height: 1.3333333;
  border-radius: 4px;
  color: #fff;
}

.main-background {
  background: var(--main-color) !important;
  border-color: var(--main-color) !important;
}

.card-body {
  padding: 0.75rem;
}

.item-img {
  width: 20px;
  height: 20px;
}

.dashboard {
  border-bottom: 1px solid #ccc;
}

.f-15 {
  font-size: 15px;
}

@media (max-width: 768px) {
  .main-body {
    width: 90%;
  }
}

@media (min-width: 1600px) {
  .main-body {
    width: 60%;
  }
}

.flex-grow {
  flex-grow: 1;
}

.modal-w-lg {
  max-width: 900px;
}

.cdk-overlay-container {
  z-index: 1060;
}

.toast-info {
  background-color: #006db0;
}
